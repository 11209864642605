import React from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";

import { isSet } from "utils";
import { IAction } from "actions";
import { IState } from "states/states";

import "./FileUploadBox.scss";

interface IFileUploadBoxExtProps {
  children: JSX.Element;
  className?: string;
  selectedFileChanged: (filepath: File | undefined) => void;
}

interface IFileUploadBoxState {
  currentAlert?: string | JSX.Element;
}

interface IFileUploadBoxProps {}

const mapStateToProps = (state: IState) => {
  const props: IFileUploadBoxProps = {};
  return props;
};

interface IFileUploadBoxDispatch {}

const mapDispatchToProps = (dispatch: (action: IAction<any>) => void) => {
  const dispatchProps: IFileUploadBoxDispatch = {};
  return dispatchProps;
};

class FileUploadBoxComponent extends React.Component<
  IFileUploadBoxProps & IFileUploadBoxExtProps & IFileUploadBoxDispatch & WrappedComponentProps,
  IFileUploadBoxState
> {
  state = {
    currentAlert: undefined,
  };

  public render() {
    return (
      <>
        {isSet(this.state.currentAlert) && (
          <div className="alert alert-danger m-4">{this.state.currentAlert}</div>
        )}
        <div
          className={`fileUploadDiv ${this.props.className}`}
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.dataTransfer.effectAllowed = "all";
            e.dataTransfer.dropEffect = "copy";
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.dataTransfer.effectAllowed = "all";
            e.dataTransfer.dropEffect = "copy";
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.dataTransfer.effectAllowed = "all";
            e.dataTransfer.dropEffect = "copy";

            if (e.dataTransfer.files?.length === 1) {
              if (e.dataTransfer.files?.[0].name.endsWith(".xlsx")) {
                this.setState({ currentAlert: undefined });
                this.props.selectedFileChanged(e.dataTransfer.files?.[0]);
              } else {
                this.setState({
                  currentAlert: (
                    <FormattedMessage id="Only .xlsx files are supported for import!" />
                  ),
                });
              }
            }
            if (e.dataTransfer.files?.length > 1) {
              this.setState({
                currentAlert: <FormattedMessage id="Only 1 file at once allowed on import!" />,
              });
            }
          }}
        >
          <div className="file">
            <p className="center">
              <b>
                <FormattedMessage id="Import data from file" />
              </b>
            </p>
            <div className="center">
              <span className="me-1 ">
                <FormattedMessage id="Drop file here or" />
              </span>
              <span
                className="link"
                onClick={(e) => {
                  document.getElementById("file_upload")?.click();
                }}
              >
                <FormattedMessage id="click to upload" />
              </span>
              <Input
                id="file_upload"
                type="file"
                accept="applicaton/xlsx"
                onChange={(e) => {
                  this.props.selectedFileChanged(e.target.files?.[0]);
                }}
              />
            </div>
            <div className="fileHint">{this.props.children}</div>
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FileUploadBoxComponent));
